import { Component, HostListener, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import AOS from 'aos';
import { Meta } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SeoService } from '@services/seo/seo.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        HeaderComponent,
        FooterComponent,
        HttpClientModule,
        TranslateModule,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    private platformId = inject<Object>(PLATFORM_ID);
    private meta = inject(Meta);

    isSticky: boolean = false;
    showScroll: boolean = false;
    showScrollHeight = 300;
    hideScrollHeight = 10;
    readonly #seoService = inject(SeoService);

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            AOS.init({ easing: 'linear', once: true, disable: 'mobile' });
        }
    }

    @HostListener('window:scroll')
    checkScroll() {
        const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

        if (scrollPosition >= this.showScrollHeight) {
            this.showScroll = true;
        } else {
            this.showScroll = false;
        }

        this.isSticky = window.pageYOffset > 0;
    }

    scrollToTop() {
        (function smoothscroll() {
            var currentScroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 5);
            }
        })();
    }
}
